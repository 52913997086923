<template>
    <div class="share-wrapper">
        <Input name="Email address" v-model="item.email" @keyup.enter="shareInvoice" :error="errors.email" placeholder="Enter email address"/>
    </div>
</template>

<script>
    export default {
        props: ['modal', 'invoice'],
        data() {
            return {
                item: {
                    email: !this.invoice ? '' : this.invoice.carrier.invoiceEmail ? this.invoice.carrier.invoiceEmail : ''
                },
                errors: {}
            }
        },
        methods: {
            async shareInvoice() {
                this.modal.processing(true);
                this.errors = {}
                await this.ajax('SendInvoiceViaEmail', {
                    url: `/dispatch/invoice/${this.invoice._id}/SendViaEmail`,
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.modal.close();
                });
                this.modal.processing(false);
            }
        },
        mounted() {
            this.modal.onConfirm(() => {
                this.shareInvoice();
            });
        }
    }
</script>

<style lang="scss" scoped>
.share-wrapper {
    padding: 20px;
}
</style>