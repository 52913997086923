<template>
    <div class="text-overflow">#{{ item.invoiceId }}</div>
    <div class="text-overflow"><a href="" @click.prevent>{{ item.carrier.companyName }}</a></div>
    <div class="text-overflow">{{ createdOn }}</div>
    <div class="text-overflow status-holder">
        <div class="status" :class="[item.showStatus]">{{ item.showStatus }}</div>
    </div>
    <div class="text-overflow">{{ dueDate }}</div>
    <div class="text-overflow total"><span class="strong">${{ CONVERT_PRICE(Number(item.total).toFixed(2)) }}</span>
    </div>
    <div class="text-overflow">
        <div class="profile">
            <div class="image" :style="[`background-image: url('${userImage}')`]"></div>
            <div class="displayName"><a href="" @click.prevent>{{ userName }}</a></div>
        </div>
    </div>
    <div>
        <a href="" @click.prevent="$emit('tracking')" style="display: flex; gap: 5px; align-items: center;">
            <span><i class="fa-solid fa-eye"></i></span>
            <span>{{ item.trackers && item.trackers.count ? item.trackers.count : 0 }}</span>
        </a>
    </div>
    <div>
        <a href="" @click.prevent="$emit('emails')"  v-if="item.emailTrackers.sent > 0">
            <span v-if="item.emailTrackers.viewed < item.emailTrackers.sent">{{ item.emailTrackers.viewed }} / {{ item.emailTrackers.sent }}</span>
            <span style="font-size: 16px;" v-else><i class="fa-solid fa-circle-check"></i></span>
        </a>

    </div>
    <div>
        <DropDownList class="invoice-status" :disabled="item.status === 'canceled'" :class="[item.status]"
            @select="statusChange" v-model="item.status" position="right" :options="statusList" selectAttribute="value"
            showAttribute="name" />
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['item'],
    emits: ['statusChange', 'tracking', 'emails'],
    data() {
        return {
            statusList: [
                { value: 'pending', name: 'Awaiting payment' },
                { value: 'paid', name: 'Paid' },
                { value: 'canceled', name: 'Canceled' },
            ],
            lastStatus: this.item.status,
            showStatus: this.item.showStatus
        }
    },
    methods: {
        async statusChange(status) {
            if (status.value === 'canceled') {
                let confirmCancel = await this.$ShowConfirm(`Do you really wish to cancel this invoice? You won't be able to undo this action.`);
                if (!confirmCancel) {
                    this.item.status = this.lastStatus;
                    return;
                }
            }
            this.item.showStatus = status.value;

            this.lastStatus = status.value;
            this.$emit('statusChange', status.value);
        }
    },
    computed: {
        createdOn() {
            return moment.utc(this.item.createdOn).format("ddd D MMM");
        },
        dueDate() {
            return moment.utc(this.item.dueDate).format("ddd D MMM");
        },
        userImage() {
            return this.item.user.image ? this.item.user.image : this.DEFAULTS.USER.IMAGE;
        },
        userName() {
            return this.item.user.displayName ? this.item.user.displayName : this.item.user.name + ' ' + this.item.user.lastname;
        },
        status() {
            return this.item.removed ? { value: 'Removed', class: 'removed' } : this.item.pending ? { value: 'Invited', class: 'pending' } : { value: 'Active', class: 'active' };
        }
    }
}
</script>

<style lang="scss" scoped>
.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 34px minmax(0, auto);
    column-gap: 15px;

    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 9px;
    }
}

.total {
    color: $success;
}

.strong {
    font-weight: 600;
}

.status-holder {
    display: flex;
}

.status {
    height: 28px;
    border-radius: 7px;
    transition: ease 0.3s;
    border: 1px solid $themeColor2;
    color: $themeColor2;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 10px 0 20px;
    position: relative;
    font-size: 11px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 7px;
        width: 6px;
        height: 6px;
        background: $themeColor2;
        border-radius: 50%;
        transform: translate(0, -50%);
    }

    &.paid {
        border: 1px solid $success;
        color: $success;

        &::before {
            background: $success;
        }
    }

    &.overdue {
        border: 1px solid $error;
        color: $error;

        &::before {
            background: $error;
        }
    }

    &.canceled {
        border: 1px solid $error;
        color: #fff;
        background: $error;

        &::before {
            background: #fff;
        }
    }
}

.invoice-status {
    height: 28px;
    background: $themeColor1;
    padding: 0 10px;
    border-radius: 14px;
    transition: ease 0.3s;
    border: 1px solid $borderColor;

    &:after {
        content: none !important;
    }

    &.pending {
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $textShade;
    }

    &.paid {
        border: 1px solid $success;
        background: $success;
        color: #fff;
    }

    &.canceled {
        border: 1px solid $error;
        background: $error;
        color: #fff;
    }
}
</style>