<template>
    <div style="padding: 20px;">
        <TableView :head="tableHead" :size="columnsSizes">
            <TableRow v-for="(item, key) of trackers" :size="columnsSizes">
                <div class="text-overflow">{{ item.email }}</div>
                <div class="emailOpened text-overflow"><span v-if="item.emailOpened"><i class="fa-solid fa-circle-check"></i></span></div>
                <div class="text-overflow">{{ item.views ? item.views.count : '0' }}</div>
                <div class="text-overflow">{{ item.views ? convertDate(item.views.firstOpenDate) : '' }}</div>
            </TableRow>
        </TableView>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['invoiceId'],
    data() {
        return {
            trackers: [],
            tableHead: [
                { name: 'Email' },
                { name: 'Opened' },
                { name: 'Views' },
                { name: 'Date' },
            ],
            columnsSizes: [
                'minmax(120px, 1fr)',
                'minmax(100px, 1fr)',
                'minmax(80px, 100px)', 
                'minmax(120px, 150px)', 
            ]
        }
    },
    methods: {
        convertDate(date) {
            return moment(date).format("D MMM YY, HH:mm");
        },
        getTrackers() {
            this.ajax('GetListOfTrackers', {
                url: `dispatch/invoice/${this.invoiceId}/email-trackers`,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this.trackers = body;
                }
            });
        }
    },
    mounted() {
        this.getTrackers();
    }
}
</script>

<style lang="scss" scoped>
.emailOpened {
    color: $success;
    font-size: 16px;
}
</style>