<template>
    <div class="wrapper grid row-50">

        <ActionBar title="Invoices">
            <div style="display: flex; gap: 10px;">
                <Button text="Create Invoice" size="small" @click="createInvoice" />
                <Button text="Refresh" theme="white" size="small" @click="refresh" />
            </div>
        </ActionBar>


        <div class="grid cols-4 gap-20">

            <StatsItem icon="fa-solid fa-file-invoice-dollar" text="Number of invoices"
                :loading="isLoading(['GetListOfInvoices'])">
                <span>{{ _pageManager.count }} {{ _pageManager.count === 1 ? 'invoice' : 'invoices' }}</span>
            </StatsItem>

            <StatsItem icon="fa-solid fa-piggy-bank" text="Paid amount" :loading="isLoading(['GetInvoicesStats'])">
                <span v-if="stats.paid">${{ CONVERT_PRICE(stats.paid.total.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

            <StatsItem icon="fa-regular fa-hourglass-half" text="Due amount" :loading="isLoading(['GetInvoicesStats'])">
                <span v-if="stats.due">${{ CONVERT_PRICE(stats.due.total.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

            <StatsItem icon="fa-solid fa-clock-rotate-left" text="Overdue amount"
                :loading="isLoading(['GetInvoicesStats'])">
                <span v-if="stats.overdue">${{ CONVERT_PRICE(stats.overdue.total.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

        </div>


        <div class="grid row-20">

            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search by invoice #" />

            <div class="page-filters">

                <div class="filter-item">
                    <div class="filter-item-name">Date</div>
                    <DropDownDatePicker v-model="_pageManager.query.date" placeholder="Select date range" range
                        mode="date" />
                </div>

                <div class="filter-item">
                    <div class="filter-item-name">Carrier</div>
                    <DropDownCheckbox v-model="_pageManager.query.carrier" placeholder="All" selectAttribute="_id"
                        showAttribute="companyName" :options="carriers" />
                </div>

                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value"
                        showAttribute="name" :options="statusList" />
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)"
                    :loading="isLoading(['GetListOfInvoices'])">
                    <TransitionGroup name="list">
                        <TableRow :size="columnsSizes" :key="item._id" v-for="item of _pageManager.list"
                            :menu="[{ name: 'View invoice', emit: 'view' }, { name: 'Send via email', emit: 'send' }, { name: 'Download PDF', emit: 'download' }]"
                            @view="viewInvoice(item._id)" @download="downloadInvoice(item._id)"
                            @send="sendInvoiceViaEmail(item)">
                            <InvoiceItem :item="item" @tracking="openInvoiceTrackers(item._id)" @emails="openEmailTrackers(item._id)"
                                @statusChange="(status) => { changeInvoiceStatus(item._id, status) }" />
                        </TableRow>
                    </TransitionGroup>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetListOfInvoices'])" class="spinner-flex">
                        <Spinner />
                    </div>
                    <div v-else>
                        <NoDataMessage text="Nothing found" />
                    </div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }" />
            </div>

        </div>

    </div>
</template>

<script>
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import moment from 'moment'

import CreateInvoice from '../../components/Invoices/Create.vue'
import ShareInvoice from '../../components/Invoices/ShareInvoice.vue'
import InvoiceItem from '../../components/Invoices/InvoiceItem.vue'
import Trackers from '../../components/Invoices/Tracking.vue'
import EmailTrackers from '../../components/Invoices/EmailTrackers.vue'
import StatsItem from '../../components/StatsItem.vue'


export default {
    mixins: [PaginationJS],
    components: {
        Pagination,
        InvoiceItem,
        StatsItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Invoice #' },
                { name: 'Carrier' },
                { name: 'Created on' },
                { name: 'Date status' },
                { name: 'Due date' },
                { name: 'Total' },
                { name: 'Created by' },
                { name: 'Views' },
                { name: 'Emails' },
                { name: 'Status' },
                { name: '' },
            ],
            columnsSizes: [
                '60px', // invoice number
                'minmax(100px, 1fr)', // carrier
                'minmax(80px, 100px)', // created on
                '85px', // date status
                '80px', // due date
                '100px', // total to pay
                'minmax(120px, 1fr)', // created by
                '60px', // views
                '60px', // email
                'minmax(120px, 170px)', // status
                '34px', // button
            ],
            statusList: [
                { value: null, name: 'All' },
                { value: 'due', name: 'Due' },
                { value: 'paid', name: 'Paid' },
                { value: 'overdue', name: 'Overdue' },
                { value: 'canceled', name: 'Canceled' }
            ],
            carriers: [],
            stats: {}
        }
    },
    watch: {
        "_pageManager.query.search": {
            deep: true,
            handler() {
                this._pageManager.query.page = 1;
            }
        },
        "_pageManager.query": {
            deep: true,
            handler() {
                this.refresh();
            }
        }
    },
    methods: {
        refresh() {
            this.getInvoices();
            this.getInvoicesStats();
        },
        openInvoiceTrackers(invoiceId) {
            let modal = this.$ShowModal({
                title: 'Invoice Trackers',
                component: Trackers,
                props: {
                    invoiceId
                },
                width: '776px'
            });
        },
        openEmailTrackers(invoiceId) {
            let modal = this.$ShowModal({
                title: 'Email Trackers',
                component: EmailTrackers,
                props: {
                    invoiceId
                },
                width: '776px'
            });
        },
        sendInvoiceViaEmail(invoice) {
            let modal = this.$ShowModal({
                title: 'Send Invoice Via Email',
                description: 'Enter email address to send invoice to',
                component: ShareInvoice,
                props: {
                    invoice
                }
            });
        },
        viewInvoice(invoiceId) {
            window.open(`/invoice/${invoiceId}`, '_blank');
        },
        downloadInvoice(invoiceId) {
            window.open(this.API_URL + `/dispatch/invoice/${invoiceId}/download`, '_blank');
        },
        changeInvoiceStatus(invoiceId, status) {
            this.ajax('HandleInvoiceStatusChange', {
                url: `/dispatch/invoice/${invoiceId}/status/${status}`,
                method: 'put',
            }, (err, body) => { });
        },
        getListOfCarriers() {
            this.ajax('GetListOfCarriers', {
                url: '/dispatch/carriers/all',
                method: 'GET',
            }, (err, body) => {
                if (!err) this.carriers = body;
            });
        },
        createInvoice() {
            let modal = this.$ShowModal({
                title: 'Create Invoice',
                description: 'Select carrier and loads to be invoiced',
                component: CreateInvoice
            });
        },
        getInvoicesStats() {
            let fullQuery = JSON.parse(JSON.stringify(this._pageManager.query));
            if (fullQuery.date) {
                fullQuery.start_date = fullQuery.date.start;
                fullQuery.end_date = fullQuery.date.end;
                delete fullQuery.date;
            }
            let query = new URLSearchParams(fullQuery).toString();

            this.ajax('GetInvoicesStats', {
                url: '/dispatch/invoices/stats?' + query,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this.stats = body;
                }
            });
        },
        getInvoices() {
            let fullQuery = JSON.parse(JSON.stringify(this._pageManager.query));
            if (fullQuery.date) {
                fullQuery.start_date = fullQuery.date.start;
                fullQuery.end_date = fullQuery.date.end;
                delete fullQuery.date;
            }
            let query = new URLSearchParams(fullQuery).toString();

            this.ajax('GetListOfInvoices', {
                url: '/dispatch/invoices?' + query,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this._pageManager.pages = body.pages;
                    this._pageManager.count = body.count;
                    this._pageManager.list = body.list;
                    this._pageManager.page = body.page;
                    this._pageManager.hasNextPage = body.hasNextPage;
                    this._pageManager.hasPrevPage = body.hasPrevPage;
                }
            });
        },
        onInvoiceUpdate(body) {
            let i = 0;
            let updated = false;
            for (const item of this._pageManager.list) {
                if (item._id === body._id) {
                    this._pageManager.list[i] = body;
                    updated = true;
                    break;
                }
                i++;
            }

            if (updated) {
                this.checkIfUpdatedItemsMatchingQuery();
            } else {
                if (this.isItemMatchingSearchQuery(body)) {
                    this._pageManager.list.push(body);
                    this.sortItemsList();
                }
            }
            this.getInvoicesStats();
        },
        onInvoiceInsert(body) {
            let isMatching = this.isItemMatchingSearchQuery(body);
            if (isMatching) {
                this._pageManager.count++;
                this._pageManager.list.push(body);
                this.sortItemsList();
                this.getInvoicesStats();
            }
        },
        sortItemsList() {
            this._pageManager.list = this._pageManager.list.sort((a, b) => (a.createdOn > b.createdOn) ? -1 : ((b.createdOn > a.createdOn) ? 1 : 0));
        },
        checkIfUpdatedItemsMatchingQuery() {
            let newList = this._pageManager.list.filter(item => {
                let isMatching = this.isItemMatchingSearchQuery(item);
                if (isMatching) return true;
                return false;
            });
            this._pageManager.list = newList;
        },
        isItemMatchingSearchQuery(body) {
            try {
                let bodyDate = moment.utc(body.date);
                let start = moment.utc(this._pageManager.query.date.start);
                let end = moment.utc(this._pageManager.query.date.end);
                if (bodyDate.diff(start) < 0 || bodyDate.diff(end) > 0) return false;
            } catch (e) { }


            let carriers = this._pageManager.query.carrier;
            if (carriers) {
                if (Array.isArray(carriers) && carriers.length > 0 && !carriers.includes(body.carrier._id)) return false;
            }

            let status = this._pageManager.query.status;
            if (status && status !== body.showStatus) return false;

            return true;
        }
    },
    mounted() {
        this.getInvoices();
        this.getInvoicesStats();
        this.getListOfCarriers();
        this.$WebSocket.on('/dispatch/invoices/InvoiceUpdate', 'admin-invoices', this.onInvoiceUpdate);
        this.$WebSocket.on('/dispatch/loads/InvoiceInsert', 'admin-invoices', this.onInvoiceInsert);
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    max-width: 1300px;
    margin: 0 auto;
}

.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}


.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>