<template>
    <div class="invoice-wrapper">
        
        <div class="grid gap-20">
            <div class="grid gap-15">
                <div class="grid gap-10">
                    <InputDropDownDatepicker v-model="item.dueDate" :error="errors.dueDate" placeholder="Select due date" name="Due date"/>
                    <div><a href="" @click.prevent="setPaymentTermsTo(7)">7 days</a>&nbsp;|&nbsp;<a href="" @click.prevent="setPaymentTermsTo(14)">14 days</a>&nbsp;|&nbsp;<a href="" @click.prevent="setPaymentTermsTo(30)">30 days</a></div>
                </div>
                <InputDropDownList :error="errors.carrier" name="Carrier" v-model="item.carrier" placeholder="Select carrier" selectAttribute="_id" :showAttribute="(option) => { return option.carrier.companyName }" :options="carriers"/>
                <div class="input" v-if="item.carrier">
                    <div class="_header">
                        <label>Loads</label>
                        <span class="_error">{{ errors.loads }}</span>
                    </div>

                    <div class="loads-picker">
                        <div class="loads-list" v-if="item.loads && item.loads.length > 0">{{ item.loads.length }} {{ item.loads.length === 1 ? 'load' : 'loads' }} selected</div>
                        <div class="loads-list" v-else>No loads selected</div>
                        <a href="" @click.prevent="selectLoads">Select loads</a>
                    </div>
                </div>
            </div>

            <div class="invoice-total" v-if="validatedInvoice">Total: <strong>${{ CONVERT_PRICE(validatedInvoice.total.toFixed(2)) }}</strong></div>
        </div>
        
        <div v-if="isLoading(['ValidateInvoice', 'GetListOfCarriers', 'CreateInvoice'])" class="spinner-flex"><Spinner/></div>
    </div>
</template>

<script>
import moment from 'moment';
import LoadsPicker from './LoadsPicker.vue'

    export default {
        props: ['modal'],
        data() {
            return {
                carriers: [],
                item: {
                    dueDate: moment.utc().add(7, 'days').toDate()
                },
                errors: {},
                validatedInvoice: null
            }
        },
        watch: {
            item: {
                deep: true,
                handler() {
                    this.validateInvoice();
                }
            }
        },
        methods: {
            setPaymentTermsTo(value) {
                this.item.dueDate = moment.utc().add(value, 'days').toDate();
            },
            validateInvoice() {
                this.errors = {};
                this.validatedInvoice = null;
                if(!this.item.dueDate || !this.item.carrier || !this.item.loads || this.item.loads.length <= 0) {
                    return;
                }
                this.ajax('ValidateInvoice', {
                    url: '/dispatch/invoices/validate',
                    method: 'post',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.validatedInvoice = body;
                });
            },
            createInvoice() {
                this.errors = {};
                this.ajax('CreateInvoice', {
                    url: '/dispatch/invoices',
                    method: 'post',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.modal.close();
                });
            },
            selectLoads() {
                let list = this.item.loads ? JSON.parse(JSON.stringify(this.item.loads)) : [];

                let modal = this.$ShowModal({
                    title: 'Loads',
                    description: 'Select loads to be invoiced',
                    component: LoadsPicker,
                    confirm: 'Select',
                    width: '992px',
                    props: {
                        carrier: this.item.carrier,
                        list: list
                    }
                });

                modal.onConfirm((props) => {
                    if(props && props.list) {
                        this.item.loads = props.list;
                    }
                    modal.close();
                });

            },
            getListOfCarriersWithUnpaidLoads() {
                this.ajax('GetListOfCarriers', {
                    url: '/dispatch/loads-invoices/unpaid/carriers',
                    method: 'get'
                }, (err, body) => {
                    if(err) {
                        return;
                    }
                    this.carriers = body;
                });
            }
        },
        mounted() {
            this.getListOfCarriersWithUnpaidLoads();
            this.modal.onConfirm(() => {
                this.createInvoice();
            });
        }
    }
</script>

<style lang="scss" scoped>
.invoice-wrapper {
    padding: 20px;
    position: relative;
}

.invoice-total {
    font-size: 20px;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.loads-picker {
    display: grid;
    row-gap: 10px;
    justify-items: start;
}

.loads-list {
    color: $textShade;
}
</style>