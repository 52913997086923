<template>
    <div style="padding: 20px;">
        <TableView :head="tableHead" :size="columnsSizes">
            <TableRow v-for="(item, key) of trackers" :size="columnsSizes">
                <div class="text-overflow"><a :href="`https://whatismyipaddress.com/ip/${item.details.ip}`" target="_blank">{{ item.details.ip }}</a></div>
                <div class="text-overflow">{{ getGeoCity(item.details.geo) }}</div>
                <div class="text-overflow">{{ item.views }}</div>
                <div class="text-overflow">{{ convertDate(item.details.date) }}</div>
            </TableRow>
        </TableView>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['invoiceId'],
    data() {
        return {
            trackers: [],
            tableHead: [
                { name: 'IP' },
                { name: 'Location' },
                { name: 'Views' },
                { name: 'Date' },
            ],
            columnsSizes: [
                'minmax(120px, 1fr)',
                'minmax(100px, 1fr)',
                'minmax(80px, 100px)', 
                'minmax(120px, 150px)', 
            ]
        }
    },
    methods: {
        getGeoCity(geo) {
            if(!geo) return;
            const list = [];
            if(geo.city) list.push(geo.city);
            if(geo.region) list.push(geo.region);
            if(geo.country) list.push(geo.country);

            return list.join(", ");
        },
        convertDate(date) {
            return moment(date).format("D MMM YY, HH:mm");
        },
        getTrackers() {
            this.ajax('GetListOfTrackers', {
                url: `dispatch/invoice/${this.invoiceId}/trackers`,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this.trackers = body;
                }
            });
        }
    },
    mounted() {
        this.getTrackers();
    }
}
</script>

<style lang="scss" scoped></style>