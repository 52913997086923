<template>
    <div class="loads-picker-wrapper">

        <TableView :head="table.head" :size="table.sizes" :loading="isLoading(['getListOfUnpaidLoads'])">
            <TableRow  :size="table.sizes" :key="load._id" v-for="load of loads">
                <div><Checkbox v-model="selected" :val="load._id"/></div>
                <div>{{ getLoadDate(load.date) }}</div>
                <div>{{ load.origin }}</div>
                <div>{{ load.destination }}</div>
                <div>${{ CONVERT_PRICE(load.rate.toFixed(2)) }}</div>
                <div><strong>${{ CONVERT_PRICE(load.carrierCharge.amount.toFixed(2)) }}</strong></div>
                <div>{{ CONVERT_PRICE(load.carrierCharge.percent.toFixed(2)) }}%</div>
            </TableRow>
        </TableView>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['carrier', 'list', 'modal'],
        data() {
            return {
                table: {
                    head: [
                        { name: '' },
                        { name: 'Date' },
                        { name: 'Origin' },
                        { name: 'Destination' },
                        { name: 'Rate' },
                        { name: 'Charge' },
                        { name: 'Percent' },
                    ],
                    sizes: ['34px', 'minmax(80px, 100px)', 'minmax(120px, 1fr)', 'minmax(120px, 1fr)', 'minmax(80px, 100px)','minmax(80px, 100px)','minmax(50px, 70px)']
                },
                loads: [],
                selected: this.list || []
            }
        },
        methods: {
            getLoadDate(value) {
                return moment.utc(value).format("ddd D MMM")
            },
            getListOfUnpaidLoads() {
                this.ajax('getListOfUnpaidLoads', {
                    url: `/dispatch/loads-invoices/unpaid/${this.carrier}`,
                    method: 'get'
                }, (err, body) => {
                    if(err) {
                        this.modal.close();
                        return;
                    }
                    this.loads = body;
                })
            }
        },
        mounted() {
            this.getListOfUnpaidLoads();
        },
        watch: {
            selected() {
                this.modal.setProp('list', this.selected);
            }
        }
    }
</script>

<style lang="scss" scoped>
.loads-picker-wrapper {
    padding: 10px;
}


</style>